import { get, post } from './axios'
import news from './news-console'

const getTestData = async (): Promise<any> => {
  const res = await get('/getFileList')

  return res
}

const sendEmail = async (data: any): Promise<any> => {
  const res = await post('/mail/send', data)

  return res
}

const saveNewsData = async (data: any) => {
  const res = await post(`/website/news/insert`, data)

  return res
}

const getNewsData = async (data: any) => {
  const res = await get(`/website/news/get`, data)

  return res
}

// v2 在线联系
const sendOnlineMessage = (data: any) => {
  return post('mail/send/online/message', data)
}

export default {
  saveNewsData,
  getNewsData,
  getTestData,
  sendEmail,
  sendOnlineMessage,
  ...news,
}
