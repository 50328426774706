import { get, post } from './axios'

const getNews = async (data: any) => {
  const res = await post('/website/news/getList', data)

  return res
}

const addOrUpdateNews = async (data: any) => {
  const res = await post('/website/news/insert', data)

  return res
}

const deleteNews = async (data: any) => {
  const res = await get(`/website/news/delete`, data)

  return res
}

export default {
  getNews,
  addOrUpdateNews,
  deleteNews,
}
